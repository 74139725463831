<template>
    <div class="grey-bg">
        <div class="message rich-text text-justify-t" v-html="helpAbout.body">
        </div>
    </div>
</template>

<script>
export default {
name: "show",
    data(){
        return{
            helpAbout: ''
        }
    },
    created() {
        this.getAboutShow()
    },
    methods:{
        // 获取帮助反馈
        getAboutShow(){
            let _this = this
            let url = _this.$api.Contents + '/' + this.$route.params.id
            _this.$http.get(url,true).then( res => {
                if(res.data.success){
                    _this.helpAbout = res.data.data;
                    this.$store.commit('M_TITLE', _this.helpAbout.title)
                }
            } )
        }
    }
}
</script>

<style scoped>

</style>